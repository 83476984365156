/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-1-not-quite-lisp"
  }, "Day 1: Not Quite Lisp"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2015/day/1"
  }, "https://adventofcode.com/2015/day/1")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2015/src/day_01.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "Santa’s weather machine’s snow function is powered by stars.\nDuring Advent of Code, each puzzle solution rewards you with a star.\nHooray, a convenient way to help."), "\n", React.createElement(_components.p, null, "Santa’s in an apartment building and has directions that tell him to go up or down 1 floor."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "("), " go up one floor"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, ")"), " go down one floor"), "\n"), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "(()(()(\n")), "\n", React.createElement(_components.p, null, "The building is very tall (and deep!), Santa will never reach the top (or bottom) floor."), "\n", React.createElement(_components.p, null, "Santa starts at the ground floor (number ", React.createElement(_components.code, null, "0"), ")."), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "The question asks what floor Santa ends at after following the instructions."), "\n", React.createElement(_components.p, null, "This asks to turn a sequence of something into a singular answer."), "\n", React.createElement(_components.p, null, "Ideal for your favourite language’s method that does that.\nRust has ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/stable/std/iter/trait.Iterator.html#method.fold"
  }, React.createElement(_components.code, null, "fold")), ", JavaScript has ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce"
  }, React.createElement(_components.code, null, "reduce")), "."), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-1"
  }, "Main code for part 1"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs"
  }, "pub fn part_1(input: &str) -> usize {\n    input.chars().fold(0, |floor, c| match c {\n        '(' => floor + 1,\n        ')' => floor - 1,\n        _ => panic!(\"invalid input\"),\n    })\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The question asks for the position of the first character that causes Santa to enter the basement."), "\n", React.createElement(_components.p, null, "The basement starts at ", React.createElement(_components.code, null, "-1"), "."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "On many days during Advent of Code puzzles ask for positions that start at 1, not 0!"), React.createElement(_components.p, null, "This is one of those days.")), "\n", React.createElement(_components.p, null, "This was fun, I made 3 variants that do the same thing."), "\n", React.createElement(_components.h3, {
    id: "old-reliable"
  }, "Old reliable"), "\n", React.createElement(_components.p, null, "A workhorse.\nA ", React.createElement(_components.code, null, "for"), " loop."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut floor = 0;\nfor (idx, c) in input.chars().enumerate() {\n    match c {\n        '(' => floor += 1,\n        ')' => floor -= 1,\n        _ => panic!(\"invalid input\"),\n    }\n    if floor < 0 {\n        return idx + 1;\n    }\n}\npanic!(\"Santa never enters the basement\");\n")), "\n", React.createElement(_components.h3, {
    id: "a-short-circuiting-fold"
  }, "A short-circuiting fold."), "\n", React.createElement(_components.p, null, "In other words, one that stops as soon as a condition is met."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "input\n    .chars()\n    .enumerate()\n    .try_fold(0, |floor, (idx, c)| match c {\n        '(' => Ok(floor + 1),\n        ')' if floor > 0 => Ok(floor - 1),\n        ')' => Err(idx + 1),\n        _ => panic!(\"invalid input\"),\n    })\n    .unwrap_err()\n")), "\n", React.createElement(_components.h3, {
    id: "my-favourite"
  }, "My favourite"), "\n", React.createElement(_components.p, null, "And finally, my favourite, a solution that creates a sequence of the floor Santa is currently on."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "input\n    .chars()\n    // create iterator where each item is the current floor Santa is on, starting at floor 0\n    .scan(0, |floor, c| {\n        match c {\n            '(' => *floor += 1,\n            ')' => *floor -= 1,\n            _ => panic!(\"invalid input\"),\n        }\n        Some(*floor)\n    })\n    // find the first index where santa enters the basement\n    .position(|floor| floor < 0)\n    .unwrap()\n    + 1\n")), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-2"
  }, "Main code for part 2"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs"
  }, "pub fn part_2(input: &str) -> usize {\n    input\n        .chars()\n        // create iterator where each item is the current floor Santa is on, starting at floor 0\n        .scan(0, |floor, c| {\n            match c {\n                '(' => *floor += 1,\n                ')' => *floor -= 1,\n                _ => panic!(\"invalid input\"),\n            }\n            Some(*floor)\n        })\n        // find the first index where santa enters the basement\n        .position(|floor| floor < 0)\n        .unwrap()\n        + 1\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs",
    numberLines: true
  }, "pub fn part_1(input: &str) -> usize {\n    input.chars().fold(0, |floor, c| match c {\n        '(' => floor + 1,\n        ')' => floor - 1,\n        _ => panic!(\"invalid input\"),\n    })\n}\n\npub fn part_2(input: &str) -> usize {\n    input\n        .chars()\n        // create iterator where each item is the current floor Santa is on, starting at floor 0\n        .scan(0, |floor, c| {\n            match c {\n                '(' => *floor += 1,\n                ')' => *floor -= 1,\n                _ => panic!(\"invalid input\"),\n            }\n            Some(*floor)\n        })\n        // find the first index where santa enters the basement\n        .position(|floor| floor < 0)\n        .unwrap()\n        + 1\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
